import { css } from "@emotion/core"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import GatsbyImage from "gatsby-image"
import React from "react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import tw from "tailwind.macro"
const Links = [
  { to: "/", scroll: "top", label: "Home" },
  { to: "/", scroll: "about", label: "About" },
  { to: "/", scroll: "location", label: "Location" },
  { to: "/", scroll: "details", label: "Details" },

  { to: "/", scroll: "photos", label: "Photos" },
]
var SliderSettings = {
  dots: false,
  infinite: true,
  speed: 1000,
  fade: true,

  autoplaySpeed: 2000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
}

const MapKey = "AIzaSyBZ2TvmaummJpKUVeuR5Yb2AMamR3r23lE"
const Location = {
  lat: 44.0471034,
  lng: 15.3346095,
}
const IndexPage = ({ data }) => {
  return (
    <>
      <div
        className="flex mx-auto lg:h-screen lg:overflow-hidden"
        css={css`
          max-width: 1920px;
        `}
      >
        <div className="fixed z-10 w-full bg-white shadow-lg lg:w-64 lg:h-full">
          <div className="flex items-center justify-between h-full px-4 py-6 mx-auto lg:px-10 lg:py-16 lg:flex-col lg:items-start">
            <h1 className="my-0 text-3xl lg:text-5xl">
              <Link to="/" className="font-normal text-gray-900">
                Villa Jurenda
              </Link>
            </h1>
            <div className="hidden lg:block">
              <nav className="flex flex-col">
                {Links.map((item, i) => {
                  return (
                    <Link
                      to={item.to}
                      key={i}
                      className="mb-3 font-normal text-gray-800"
                      onClick={(e) => {
                        if (item.scroll) {
                          var el = document.getElementById(item.scroll)
                          if (el) {
                            e.preventDefault()
                          }
                          el && el.scrollIntoView({ behavior: "smooth" })
                        }
                      }}
                    >
                      {" "}
                      {item.label}
                    </Link>
                  )
                })}
              </nav>
            </div>
            <div>
              <a
                href="tel:+385917279042"
                className="px-2 py-1 text-sm text-white uppercase bg-blue-700 lg:text-base lg:px-5 lg:py-2 focus:outline-none"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 hidden lg:block lg:w-64"></div>
        <div
          id="top"
          className="overflow-x-hidden overflow-y-auto lg:h-screen"
          css={css`
            /* @lg */
            @media (max-width: 1023px) {
              margin-top: 78px;
            }
            .slick-next {
              right: 20px;
            }
            .slick-prev {
              left: 0px;
              z-index: 10;
            }
          `}
        >
          <Slider {...SliderSettings}>
            {data.allFile.nodes.map((file) => {
              return (
                <div className="w-full" key={file.base}>
                  <BackgroundImage fluid={file.sharp.fluid}>
                    <div
                      className="w-full h-100 lg:h-screen"
                      css={css`
                        max-height: 1080px;
                      `}
                    ></div>
                  </BackgroundImage>
                </div>
              )
            })}
          </Slider>
          <div className="center" id="about">
            <div
              className="prose "
              dangerouslySetInnerHTML={{ __html: data.about.html }}
            ></div>
          </div>

          <div id="location" className="mt-20">
            <div className="prose center">
              {" "}
              <h1>Location</h1>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d45891.304468243645!2d15.305024458322295!3d44.03778897097848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4761e538d528d437%3A0x1635632c77d55adc!2sVilla%20Jurenda!5e0!3m2!1sen!2shr!4v1594880695627!5m2!1sen!2shr"
              width="100%"
              height={450}
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen
              aria-hidden="false"
              tabIndex={0}
            />
          </div>
          <Details details={data.details.frontmatter} />
          <Photos photos={data.images.nodes} />
          <Prices prices={data.prices.frontmatter.prices} />
          {/* <Contact /> */}
          <Footer />
        </div>
      </div>
    </>
  )
}

export default IndexPage
export const query = graphql`
  {
    allFile(
      filter: { base: { regex: "/07|17|26|43|56|62|68|75/" } }
      sort: { fields: base, order: ASC }
    ) {
      nodes {
        base
        sharp: childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    about: markdownRemark(fileAbsolutePath: { regex: "/about/" }) {
      html
    }
    details: markdownRemark(fileAbsolutePath: { regex: "/details/" }) {
      frontmatter {
        General
        Bedrooms
        Bathrooms
        Kitchen
        Living_room
        Distances
      }
    }
    prices: markdownRemark(fileAbsolutePath: { regex: "/prices/" }) {
      frontmatter {
        prices {
          from
          to
          minStay
          price
        }
      }
    }
    images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      nodes {
        sharp: childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

function Details({ details }) {
  return (
    <div id="details" className="pt-10">
      <div className="prose center">
        <h1>Details</h1>
      </div>
      <div className="bg-gray-100">
        <div className="center">
          <div className="flex-wrap justify-around py-6 lg:flex ">
            {[
              { title: "Max Guests", content: "6" },
              { title: "Indoor Area", content: "75 m<sup>2</sup>" },
              { title: "Total Plot Area", content: "2000 m<sup>2</sup>" },
              { title: "Pool Area", content: "32 m<sup>2</sup>" },
            ].map((item) => {
              return (
                <div
                  className="flex px-4 my-6 prose lg:text-center lg:block"
                  key={item.title}
                >
                  <h4 className="mt-0 mb-1 text-xl text-blue-900">
                    {item.title}:
                  </h4>
                  <div
                    className="ml-2 lg:ml-0 "
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="prose center lg:py-10">
        <div className="row">
          <div className="w-full col sm:w-1/2 lg:w-1/3">
            {" "}
            <h3>General</h3>
            <div>
              {details.General.map((item, i) => {
                return (
                  <>
                    <div>
                      <span key={i} className="inline-block py-3 ">
                        {item}
                      </span>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div className="w-full col sm:w-1/2 lg:w-1/3">
            {" "}
            <h3>Bedrooms</h3>
            <div>
              {details.Bedrooms.map((item, i) => {
                return (
                  <>
                    <div>
                      <span key={i} className="inline-block py-3 ">
                        {item}
                      </span>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div className="w-full col sm:w-1/2 lg:w-1/3">
            {" "}
            <h3>Bathrooms</h3>
            <div>
              {details.Bathrooms.map((item, i) => {
                return (
                  <>
                    <div>
                      <span key={i} className="inline-block py-3 ">
                        {item}
                      </span>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div className="w-full col sm:w-1/2 lg:w-1/3">
            {" "}
            <h3>Kitchen</h3>
            <div>
              {details.Kitchen.map((item, i) => {
                return (
                  <>
                    <div>
                      <span key={i} className="inline-block py-3 ">
                        {item}
                      </span>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div className="w-full col sm:w-1/2 lg:w-1/3">
            {" "}
            <h3>Living Room</h3>
            <div>
              {details.Living_room.map((item, i) => {
                return (
                  <>
                    <div>
                      <span key={i} className="inline-block py-3 ">
                        {item}
                      </span>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div className="w-full col sm:w-1/2 lg:w-1/3">
            {" "}
            <h3>Distances</h3>
            <div>
              {details.Distances.map((item, i) => {
                return (
                  <>
                    <div>
                      <span key={i} className="inline-block py-3 ">
                        {item}
                      </span>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      css={css`
        &:before {
          display: none;
        }
      `}
      className={className}
      onClick={onClick}
    >
      <MdKeyboardArrowLeft className="w-10 h-10 text-gray-800 bg-white bg-opacity-50 hover:bg-opacity-75" />{" "}
    </div>
  )
}

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      css={css`
        &:before {
          display: none;
        }
      `}
      className={className}
      onClick={onClick}
    >
      <MdKeyboardArrowRight className="w-10 h-10 text-gray-800 bg-white bg-opacity-50 hover:bg-opacity-75" />{" "}
    </div>
  )
}

function Contact() {
  return (
    <section className="py-10 bg-gray-200 lg:py-20" id="contact">
      <div className="center">
        <div className="prose max-w-none">
          <h1 className="text-gray-100">Contact Us</h1>
          <form name="contact" method="POST" data-netlify="true">
            <div
              className="space-y-6 row lg:space-y-0"
              css={css`
                input,
                textarea {
                  width: 100%;
                  ${tw`p-3 text-base border border-gray-400`}
                }
                textarea,
                button {
                  /* @lg */
                  @media (min-width: 1024px) {
                    ${tw`mt-6`}
                  }
                }
              `}
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="w-full col lg:w-1/2">
                <input name="Name" placeholder="Name" type="text" />
              </div>
              <div className="w-full col lg:w-1/2">
                <input
                  name="Email"
                  placeholder="Email*"
                  type="email"
                  required
                />
              </div>
              <div className="w-full col ">
                <textarea
                  name="Message"
                  placeholder="Message*"
                  required
                ></textarea>
              </div>
              <div className="col">
                <button className="px-4 py-2 text-gray-100 bg-gray-800">
                  Submit
                </button>
              </div>
            </div>
          </form>
          {/* <div
            className="mt-6 lg:hidden"
            css={css`
              a {
                ${tw`font-bold text-green-600 no-underline`}
              }
            `}
          >
            ... or send us message via{" "}
            <a href="https://wa.me/+3850917279042">Whatsapp</a>
          </div> */}
        </div>
      </div>
    </section>
  )
}

function Prices({ prices }) {
  return (
    <section
      className="py-10 lg:py-20"
      css={css`
        & h1 {
          /* ${tw`text-gray-100`} */
        }
      `}
    >
      <div className="prose center">
        <h1 className="">Prices</h1>
        <table
          className="border border-gray-400 table-auto"
          css={css`
            th,
            td {
              ${tw`px-2 py-2`}
              text-align: center;
            }
          `}
        >
          <thead className="bg-gray-100">
            <th>From</th>
            <th>To</th>
            <th>Minimal Stay</th>
            <th>Price / day</th>
          </thead>
          <tbody>
            {prices.map((item, i) => {
              return (
                <tr key={i} className={i % 2 ? "bg-gray-100" : "bg-gray-200"}>
                  <td>{item.from}</td>
                  <td>{item.to}</td>
                  <td>{item.minStay} days</td>
                  <td>{item.price}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </section>
  )
}

function Photos({ photos }) {
  return (
    <section id="photos">
      <div className="center">
        <div className="prose max-w-none">
          <h1>Photos</h1>
        </div>
      </div>
      <Slider
        {...{
          dots: false,
          infinite: true,

          autoplay: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
          responsive: [
            {
              breakpoint: 1023,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        }}
      >
        {photos.map((photo, i) => {
          return (
            <div>
              <GatsbyImage
                key={i}
                loading={"eager"}
                fluid={photo.sharp.fluid}
              />
            </div>
          )
        })}
      </Slider>
    </section>
  )
}

function Footer() {
  return (
    <section className="bg-gray-800">
      <div className="my-0 center">
        <div
          className="py-8 prose-sm prose max-w-none "
          css={css`
            p {
              ${tw`text-center text-gray-200`}
            }
          `}
        >
          <p>
            Copyright © {new Date().getFullYear()}. All rights reserved. Web
            development by{" "}
            <a
              style={{ color: "#fff", opacity: 0.4 }}
              href="https://matemislov.com"
            >
              Mate Mišlov
            </a>
          </p>
        </div>
      </div>
    </section>
  )
}
